const StatusTransferResource = {
	
	NotTransfered: -1,
	ConciliedByAuc: 0,
	PendentAcceptInstitution: 1,
	AcceptedByInstitution: 2,
	TranferedToInstitution: 3,
	ConciliedByInstitution: 4,
	DeclinedByInstitution: 5
};

export { StatusTransferResource };